import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-049a8978"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "padding-left": "10px",
    "padding-right": "10px"
  }
};
const _hoisted_2 = {
  class: "my-list-icon"
};
const _hoisted_3 = {
  class: "my-list-content"
};
const _hoisted_4 = {
  class: "my-list-tops my-flex my-row my-nowrap my-space-between"
};
const _hoisted_5 = {
  class: "my-list-title"
};
const _hoisted_6 = {
  class: "my-list-gold"
};
const _hoisted_7 = {
  class: "my-list-text my-flex my-row my-nowrap my-space-between"
};
const _hoisted_8 = {
  class: "my-list-info"
};
const _hoisted_9 = {
  class: "my-list-time"
};
import { ref, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/index.js';
import { useRouter } from 'vue-router';
import { getRecordList } from '@/api/index.js';
export default {
  __name: 'records',
  setup(__props) {
    const userStore = useUserStore();
    const router = useRouter();
    const empty = ref(false);
    const is_auth = ref(false);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const types = ref([{
      info: 'Balance profit'
    }, {
      info: 'Lower level rebate'
    }, {
      info: 'Bot profit '
    }, {
      info: 'Lower level rebate'
    }, {
      info: 'Withdraw'
    }, {
      info: 'Cancel Withdraw'
    }, {
      info: 'System Gift'
    }, {
      info: 'Return of principal'
    }]);
    let senddata = {
      page: 1,
      limit: 20,
      user_addr: userStore.userInfo.user_addr
    };
    const alldata = ref([]);
    const onLoad = () => {
      getRecordList(senddata).then(data => {
        let res = data.data.data.data;
        console.log(res.length);
        if (res.length >= 1 && senddata.page == 1) {
          alldata.value = res;
        }
        if (res.length == 0) {
          finished.value = true;
          loading.value = false;
        } else {
          alldata.value.push(...res);
          finished.value = false;
          loading.value = false;
          senddata.page++;
        }
      });
    };
    watch(alldata, (newlist, oldlist) => {
      // if (newlist.length < 1) {
      //     empty.value = true
      // } else {
      //     empty.value = false
      // }
    });
    onMounted(() => {});
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    const onClickLeft = () => {
      router.push({
        path: '/'
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_van_empty = _resolveComponent("van-empty");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        fixed: true,
        placeholder: true,
        title: "Record",
        "left-text": "",
        "left-arrow": "",
        onClickLeft: onClickLeft,
        border: false
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_pull_refresh, {
        modelValue: refreshing.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => refreshing.value = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: loading.value,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
          finished: finished.value,
          "finished-text": " ",
          "loading-text": "loading...",
          onLoad: onLoad
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(alldata.value, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              class: "my-list-item my-flex my-row my-nowrap"
            }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_icon, {
              name: '/image/types/' + item.type + '.png',
              size: "32"
            }, null, 8, ["name"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(types.value[item.type - 1].info), 1), _createElementVNode("div", _hoisted_6, " $" + _toDisplayString(item.amount), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.order_id), 1)]), _createElementVNode("div", _hoisted_9, _toDisplayString(item.create_time), 1)])]);
          }), 128))]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"])]), empty.value ? (_openBlock(), _createBlock(_component_van_empty, {
        key: 0,
        description: "Empty"
      })) : _createCommentVNode("", true)], 64);
    };
  }
};